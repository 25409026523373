import API_URL from "../config/env"

export const API = API_URL.apiUrl
export const DOMAIN = API_URL.domain
export const API_ADMIN = API_URL.apiAdminUrl
export const API_ADMIN_DOMAIN = API_URL.adminDomain
export const DOMAIN_FE = API_URL.domainFe
export const DOMAIN_FE_ADMIN = API_URL.domainFeAdmin
export const appName = "furnishr"

export const POSTFIX = {
    START: "_START",
    STOP: "_STOP",
    FAILURE: "_FAILURE",
    SUCCESSFUL: "_SUCCESSFUL",
    WARNING: "_WARNING"
}

export const DIALOGS = {
    ALERT: "ALERT",
    APPROVE: "APPROVE",
    CONFIRM: "CONFIRM",
    PROMPT: "PROMPT"
}

export const WAIT_INTERVAL = 1000
