import { configureStore } from "@reduxjs/toolkit"
import proposalRoom from "./store/proposalRoom"
import proposalRoomVariant from "./store/proposalRoomVariant"
import proposalProducts from "./store/proposalProducts"

export const store = configureStore({
    reducer: {
        proposalRoom,
        proposalRoomVariant,
        proposalProducts
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});