import React, {useState} from "react";
import modal_close_icon from "../../../assets/images/modal-close.svg"
import "./style.scss";
import Button from "../Button";

const Modal = ({ isModalOpen, onSubmitModal, onClose }) => {
  const [postContent, setPostContent] = useState('');
  
  if (isModalOpen !== true) {
    return null;
  }
  const onSubmitModalContent = () => {
    onSubmitModal(postContent);
  };
  
  return (
    <section className="modal">
      <article className="modal-content p-lg-4">
        <div className="exit-icon text-end" style={{ backgroundImage: `url(${modal_close_icon})` }} onClick={onClose}></div>
        <main className="modal-mainContents">
          <div className="row modal-title-row">
            <div className="col-12 modal-title-wrapper">
              <h5 className="modal-title">Feedback</h5>
            </div>
          </div>
          <div>
            <textarea
              rows={18}
              cols={42}
              placeholder="Enter text"
              value={postContent}
              onChange={e => setPostContent(e.target.value)}
            />
          </div>
          <div className="modal-button text-end">
            <div className="row">
              <div className="col-6"></div>
              <div className="col-6 modal-buttons-wrap">
                <Button
                  type="normal"
                  text="Cancel"
                  onButtonClick={() => {
                    onClose();
                  }}
                />
                <Button
                  type="normal"
                  text="Submit"
                  onButtonClick={() => {
                    onSubmitModalContent();
                  }}
                />
              </div>
            </div>
          </div>
        </main>
      </article>
    </section>
  );
};

export default Modal;