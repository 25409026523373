import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/thumbs'
import './style.scss'
import {FreeMode, Thumbs} from "swiper/modules";

const ProductsSlider = ({product}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  
  return (
    <>
      <Swiper
        spaceBetween={10}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Thumbs]}
        className="myProductSwiper2"
      >
        <SwiperSlide>
          <img src={product?.default_image?.url} alt="previewFirst"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={product?.image2?.url} alt="previewSecond"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={product?.image3?.url} alt="previewThird"/>
        </SwiperSlide>
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Thumbs]}
        className="myProductSwiper"
      >
        <SwiperSlide>
          <img src={product?.default_image?.url} alt="previewFirst"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={product?.image2?.url} alt="previewSecond"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={product?.image3?.url} alt="previewThird"/>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default ProductsSlider;