import React from "react";
import "styled-components"
import styled from "styled-components";

const ProposalHeaderMessage = ({text}) => {
  return (
    <ProposalHeaderMessageWrapper>
      <ProposalHeaderMessageText>{text}</ProposalHeaderMessageText>
    </ProposalHeaderMessageWrapper>
  )
};
  
  export default ProposalHeaderMessage;
  
const ProposalHeaderMessageWrapper = styled.div`
  width: 100%;
  min-height: 56px;
  height: auto;
  flex-shrink: 0;
  background: var(--core-colours-warm-white, #F8F5EF);
`;

const ProposalHeaderMessageText = styled.span`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 136%;
  padding: 17px 0 10px 57px;
  display: block;
  text-align: left;
`;
