import { createSlice } from "@reduxjs/toolkit";

export const proposalRoom = createSlice({
  name: "proposal_room",
  initialState: {
    room: {
      room_variants: []
    },
    room_variants: []
  },
  reducers: {
    setCurrentRoom: (state, data) => {
      state.room = data.payload
    },
    setListRoomVariants: (state, data) => {
      state.room_variants = data.payload.room_variants
    }
  }
});

export const {
  setCurrentRoom,
  setListRoomVariants
} = proposalRoom.actions

export default proposalRoom.reducer;