import React from "react";
import { Link } from "react-router-dom"
import background from "../../../assets/images/Furnishr-Logo.svg"
import shopping_card from "../../../assets/images/shopping-cart.svg"
import account from "../../../assets/images/account_circle_filled_24px.svg"

const Header = () => {
    return (
        <div id="header">
    
            <div className="row">
                <div className="col">
                    <div className="company-logo" style={{ backgroundImage: `url(${background})` }}></div>
                </div>
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <Link to="/how-it-works" className="navigation-how-it-works">
                                <span className="how-it-works">
                                    How It Works
                                </span>
                            </Link>
                        </div>
                        <div className="col">
                            <Link to="/book-design-call" className="navigation-book-design-call">
                                <span className="book-design-call">
                                    Book a Design Call
                                </span>
                            </Link>
                        </div>
                        <div className="col">
                            <Link to="/initial-concepts" className="navigation-initial-concepts">
                                <span className="initial-concepts">
                                    Initial Concepts
                                </span>
                            </Link>
                        </div>
                        <div className="col right-menu-icons">
                            <div className="shopping-card" style={{ backgroundImage: `url(${shopping_card})` }}></div>
                            <div className="account-icon" style={{ backgroundImage: `url(${account})` }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;