import { Link, useLocation } from 'react-router-dom'
import "./style.scss";

const Breadcrumbs = (props) => {
  const location = useLocation();
  const product = props.Product;
  const roomVariantUrl = `/proposal/room/${props.roomVariant.id}`
  const parentRoomUrl = `/proposal/${props.roomVariant.parent_room_permalink}`
  const productUrl = `/proposal/rooms/${props.roomVariant.id}/products/${product?.id}`
  
  return (
    <div>
      <Link to={parentRoomUrl} className={location.pathname === parentRoomUrl ? "breadcrumb-active" : "breadcrumb-not-active"} >
        Proposal
      </Link>
      <span className="breadcrumb-arrow"> &#47; </span>
      <Link to={roomVariantUrl} className={location.pathname === roomVariantUrl ? "breadcrumb-active" : "breadcrumb-not-active"} >
        {props.roomVariant.name}
      </Link>
      <span className="breadcrumb-arrow"> &#47; </span>
      <Link to={roomVariantUrl} className={location.pathname === productUrl ? "breadcrumb-active" : "breadcrumb-not-active"} >
        {product?.name}
      </Link>
    </div>
  );
}

export default Breadcrumbs;