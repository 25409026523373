import React, { useRef, useState } from "react";
import "./style.scss";
import expand_icon from "../../../assets/images/expand-icon.svg"
import expanded_icon from "../../../assets/images/expanded-icon.svg"
import ListDesignRooms from "../ListDesignRooms/ListDesignRooms";
const Accordion = (props) => {
  const designRooms = props.design_rooms;
  const currentDesignRoom = props.current_design_room;
  const [active, setActive] = useState(false);
  const content = useRef(null);
  const [height, setHeight] = useState("0px");
  const toggleAccordion = (e) => {
    setActive(!active);
    setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
  }
  
  return (
    <div className="accordion__section">
      <div className={`design-rooms-accordion ${active ? "active" : ""}`} onClick={toggleAccordion} >
        <p className="accordion__title">Assigment</p>
        <span className="accordion__expand" style={{ marginLeft: "20px" }}>
          <span>{ currentDesignRoom?.label }</span>
          { active ? <div className="expanded-icon" style={{ backgroundImage: `url(${expanded_icon})` }}></div> : <div className="expand-icon" style={{ backgroundImage: `url(${expand_icon})` }}></div> }
        </span>
      </div>
      <div ref={content} style={{ maxHeight: `${height}` }} className="accordion__content">
        <div className="accordion__text" />
          <ListDesignRooms current_design_room={currentDesignRoom} design_rooms={designRooms} />
      </div>
    </div>
  );
}

export default Accordion;
