import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import previewFirst from "../../../assets/images/slider/slide-1.png"
import previewSecond from "../../../assets/images/slider/slide-2.png"
import previewThird from "../../../assets/images/slider/slide-3.png"
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/thumbs'
import './style.scss'
import {FreeMode, Thumbs} from "swiper/modules";

const RoomVariantImages = ({ roomVariant }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const firstImage = roomVariant.default_image?.large?.url || previewFirst
  const secondImage = roomVariant.image2?.large?.url || previewSecond
  const thirdImage = roomVariant.image3?.large?.url || previewThird
  
  return (
    <>
      <Swiper
        spaceBetween={10}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Thumbs]}
        className="mySwiper2"
      >
        <SwiperSlide>
          <img src={firstImage} alt="previewFirst"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={secondImage} alt="previewSecond"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={thirdImage} alt="previewThird"/>
        </SwiperSlide>
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Thumbs]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={previewFirst} alt="previewFirst"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={previewSecond} alt="previewSecond"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={previewThird} alt="previewThird"/>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default RoomVariantImages;