import React, {useState} from "react";
import "./style.scss";
import Button from "../../atoms/Button";
import FeedbackDetail from "./FeedbackDetail";
import Modal from "../../atoms/Modal/Modal"
import axios from "../../../../src/api/axiosConfig"
import {API} from "../../../constants";
import { setFeedbacks } from "../../../store/proposalRoomVariant"
import {useDispatch} from "react-redux";

const Feedbacks = ({RoomVariant}) => {
  const listFeedbacks = RoomVariant.feedbacks
  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useDispatch();
  const openModal = (content) => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  
  const onSubmitFeedback = (message) => {
    axios.post(`${API}/proposals/feedbacks`, {
      message: message,
      room_variant_id: RoomVariant.room_variant_id
    })
      .then((response) => {
        dispatch(setFeedbacks(response.data));
        closeModal();
      });
  };
  
  return (
    <div className="feedback-section">
      <div className="row">
        <div className="col-6 feedback-title-wrapper">
          <span className="feedback-title">
            Feedback
          </span>
        </div>
        <div className="col-6">
          <Button
            type="normal"
            text="Leave Feedback"
            onButtonClick={() => {
              openModal();
            }}
          />
        </div>
        
        <div className="feedback-section-hr"></div>
        {listFeedbacks && listFeedbacks.map((feedback, index) =>
          <FeedbackDetail key={index} feedbackData={feedback}/>
        )}
        <div className="feedback-section-hr"></div>
        <section>
          <Modal
            isModalOpen={isModalOpen}
            onSubmitModal={onSubmitFeedback}
            onClose={closeModal}
          />
        </section>
      </div>
    </div>
  )
};

export default Feedbacks;