import React from "react";
import {useNavigate} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import { Pagination } from 'swiper/modules';
import Button from "../atoms/Button";
import previewFirst from "../../assets/images/design-room-preview-1.png"
import previewSecond from "../../assets/images/design-room-preview-2.png"
import previewThird from "../../assets/images/design-room-preview-3.png"
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


const RoomVariant = ({ roomVariant }) => {
  const navigate = useNavigate();
  const firstImage = roomVariant.default_image?.large?.url || previewFirst
  const secondImage = roomVariant.image2?.large?.url || previewSecond
  const thirdImage = roomVariant.image3?.large?.url || previewThird
  const forthImage = roomVariant.image4?.large?.url || ''
  
  return (
    <div className={`col-6 package-preview`}>
      <Swiper
        slidesPerView={1}
        spaceBetween={50}
        loop={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide key="image1">
          <div className="package-preview-first-image" style={{ backgroundImage: `url(${firstImage})` }}></div>
        </SwiperSlide>
        <SwiperSlide key="image2">
          <div className="package-preview-second-image" style={{ backgroundImage: `url(${secondImage})` }}></div>
        </SwiperSlide>
        <SwiperSlide key="image3">
          <div className="package-preview-third-image" style={{ backgroundImage: `url(${thirdImage})` }}></div>
        </SwiperSlide>
        <SwiperSlide key="image4">
          <div className="package-preview-forth-image" style={{ backgroundImage: `url(${forthImage})` }}></div>
        </SwiperSlide>
      </Swiper>
      
      <div className="row">
        <div className="col-6">
          <div className="package-preview-name">
            {roomVariant.name}
          </div>
          <div className="package-preview-price">
            Package Total ${roomVariant.msrp_usd}
          </div>
        </div>
        <div className="col-6">
          <Button
            type="normal"
            text="View"
            onButtonClick={() => {
              navigate(`/proposal/room/${roomVariant.id}`);
            }}
          />
        </div>
        
      </div>
    </div>
  )
};

export default RoomVariant;