import React from "react"
import styled from "styled-components";

const Button = ({type, text, onButtonClick}) => {
  return (
    <ButtonWrapper type={type} onClick={onButtonClick}>
      <ButtonText>{text}</ButtonText>
    </ButtonWrapper>
  );
};

export default Button;

const ButtonWrapper = styled.div`
  display: flex;
  padding: 10px 24px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #BD944E;
  float: right;
`;

const ButtonText = styled.p`
  color: var(--core-colours-black, #000);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-top: revert ;
`;