import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import ProposalHeaderMessage from "../../atoms/ProposalHeaderMessage";
import RoomVariants from "./RoomVariants";
import axios from "../../../../src/api/axiosConfig"
import {API} from "../../../constants";
import {setCurrentRoom, setListRoomVariants} from "../../../store/proposalRoom";

const Room = () => {
  const dispatch = useDispatch();
  const { permalink } = useParams();
  
  useEffect(() => {
    axios
      .get(`${API}/proposals/rooms/${permalink}`)
      .then((response) => {
        dispatch(setCurrentRoom(response.data.data));
        dispatch(setListRoomVariants(response.data.data.room))
      });
  }, [dispatch, permalink]);
  
  const currentRoom = useSelector((state) => state.proposalRoom.room );
  const roomVariants = useSelector((state) => state.proposalRoom.room_variants);
  const text = currentRoom.description
  const roomName = currentRoom.name
  
  return (
    <div className="row">
      <div className="proposal-header">
        <span className="proposal-header-text">
          {roomName}
        </span>
      </div>
      <div className="proposal-body">
        <div className="row">
          <ProposalHeaderMessage text={text}/>
        </div>
        <RoomVariants roomVariants={roomVariants}/>
      </div>
    </div>
  )
};
export default Room;