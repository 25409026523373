import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import Header from "./components/organisms/header/Header";
import Proposal from "./pages/Proposal";
import Footer from "./components/organisms/footer/Footer";
import ProposalVariant from "./pages/ProposalVariant";
import Product from "./components/organisms/proposal/Product";

function App() {
  return (
    <div className="App">
      <Router>
        <Header/>
          <Routes>
            <Route path="/proposal">
              <Route path=":permalink" exact element={<Proposal />} />
            </Route>
            <Route path="/proposal/room">
              <Route path=":id" exact element={<ProposalVariant />} />
            </Route>
            <Route path="/proposal/rooms/">
              <Route path=":vid/products/:id" exact element={<Product />} />
            </Route>
          </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
