import React from "react";
import moment from "moment"

const FeedbackDetail = (props) => {
  const feedbackData = props.feedbackData
  
  return (
    <div className="row submitted-wrapper">
      <div className="col-12 submitted-header">
        <span className="title">
          Submitted
        </span>
          <span className="date">
          { moment(feedbackData.created_at).format("M/D/YY") }
        </span>
      </div>
      <div className="col-12 submitted-body">
        { feedbackData.message }
      </div>
    </div>
  )
  
}

export default FeedbackDetail