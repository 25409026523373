import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "../../../../src/api/axiosConfig"
import { API } from "../../../constants"
import {setCurrentProduct} from "../../../store/proposalProducts";
import {setCurrentRoomVariant} from "../../../store/proposalRoomVariant";
import 'swiper/css'
import Breadcrumbs from "../../atoms/breadcrumbs/Breadcrumbs";
import plus_icon from "../../../assets/images/plus.svg"
import trash_icon from "../../../assets/images/trash.svg"
import ProductsSlider from "../../molecules/ProductsSlider/ProductsSlider";
import Accordion from "../../molecules/DesignRoomsAccordion/Accordion";


const Product = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { vid } = useParams();
  const currentRoomVariant = useSelector((state) => state.proposalRoomVariant.room_variant );
  const currentProduct = useSelector((state) => state.proposalProducts.product );
  const designRooms = useSelector((state) => state.proposalRoomVariant.design_rooms);
  const currentDesignRoom = designRooms.filter((room) =>{ return room.products.map(product => product.id).includes(currentProduct.id)})[0];
  
  useEffect(() => {
    axios
      .get(`${API}/proposals/rooms/${vid}/products/${id}`)
      .then((response) => {
        dispatch(setCurrentProduct(response.data.product));
        dispatch(setCurrentRoomVariant(response.data.room_variant));
      });
  }, [id, vid, dispatch]);
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className="container">
      <div className="row breadcrumb-row">
        <div className="col">
          <Breadcrumbs roomVariant={currentRoomVariant} Product={currentProduct}/>
        </div>
      </div>
      <div className="row product-detail-info">
        <div className="col-6">
          <span className="room-variant-name">
            {currentProduct.name}
          </span>
        </div>
        <div className="col-6 product-price-container">
          <span className="product-price">
            ${currentProduct.msrp_usd}
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-9"></div>
        <div className="col-3 product-actions">
          <div className="row">
            <div className="col-6 add-to-custom-wrap">
              <div className="add-to-custom">
                <div className="add-to-custom-plus" style={{ backgroundImage: `url(${plus_icon})` }}></div>
                <span className="title">
                  Add To Custom
                </span>
              </div>
            </div>
            <div className="col-6 remove-wrap">
              <div className="remove">
                <div className="remove-trash" style={{ backgroundImage: `url(${trash_icon})` }}></div>
                <span className="title">
                  Remove
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row product-slider">
        <div className="col-7">
          <ProductsSlider product={currentProduct}/>
        </div>
        <div className="col-5 product-assigment">
          <Accordion current_design_room={currentDesignRoom} design_rooms={designRooms}/>
          <div className="row product-description">
            <span className="product-description-text">
              {currentProduct.description}
            </span>
          </div>
            <div className="row product-sizes-wrapper">
            <span className="products-sizes">
              WxDxH(in): {`${currentProduct.width} x ${currentProduct.depth} x ${currentProduct.height}`}
            </span>
          </div>
          <div className="row">
          
          </div>
        </div>
      </div>
    </div>
  )
};
export default Product;