import React from "react";
import { Link } from "react-router-dom"
import call_icon from "../../../assets/images/call.svg"
import chat_icon from "../../../assets/images/chat.svg"
import mail_outline_icon from "../../../assets/images/mail-outline.svg"
import footer_logo_icon from "../../../assets/images/Fictional-Logos.png"
import footer_phone_icon from "../../../assets/images/phone-call-01.svg"
import footer_mail from "../../../assets/images/mail-01.svg"

const Footer = () => {
  return (
    <div id="footer">
      <div className="row package-info">
        <div className="package-title">
          <span>
            Change a Package
          </span>
        </div>
        <div className="package-description">
          <span>
            Book a call, email, or live chat with us to share what changes you want to see in any package.
          </span>
        </div>
        <div className="row">
          <div className="col">
            <span className="package-call-title">
              Book a Call
            </span>
          </div>
          <div className="col">
            <span className="package-live-chat-title">
              Live Chat
            </span>
          </div>
          <div className="col">
            <span className="package-email-title">
              Email
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="package-call" style={{ backgroundImage: `url(${call_icon})` }}></div>
          </div>
          <div className="col">
            <div className="package-chat" style={{ backgroundImage: `url(${chat_icon})` }}></div>
          </div>
          <div className="col">
            <div className="package-mail-outline" style={{ backgroundImage: `url(${mail_outline_icon})` }}></div>
          </div>
        </div>
      </div>
      
      <div className="row footer-delivery-info">
        <div className="delivery-info-title">
          <span>
            Delivery Info & Return Policy
          </span>
        </div>
        <div className="row">
          <div className="container">
            <ul className="delivery-info-list">
              <li>
              <span className="list-title">
                Delivery Locations:
              </span>
              <span className="list-description">
                To all cities in the USA and Canada
              </span>
              </li>
              <li>
              <span className="list-title">
                Delivery Date:
              </span>
                <span className="list-description">
                4 to 6 weeks
              </span>
              </li>
              <li>
              <span className="list-title">
                Return Policy:
              </span>
                <span className="list-description">
                Return or exchange what you don't like within 10 days of delivery. Exchanges are free of charge. Returned items has a return fee of 15%
              </span>
              </li>
            </ul>
          </div>
          
        </div>
      </div>
      <div className="footer-wrapper">
        <div className="container-fluid footer-bottom">
          <div className="row footer-menu">
            <div className="col">
              <div className="row">
                <div className="package-mail-outline">
                  <img src={footer_logo_icon} alt="Furnishr logo"/>
                </div>
              </div>
              <div className="row footer-phone-call-wrapper">
                <div className="footer-phone-call" style={{ backgroundImage: `url(${footer_phone_icon})` }}>
                  <span className="footer-phone-number">
                    888-920-8889
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="footer-mail" style={{ backgroundImage: `url(${footer_mail})` }}>
                  <span className="footer-contact-email">
                    hi@furnishr.com
                  </span>
                </div>
              </div>
            </div>
            <div className="col"></div>
            <div className="col"></div>
            <div className="col"></div>
            <div className="col"></div>
            <div className="col"></div>
            <div className="col">
              <div className="row">
                <Link to="/about" className="navigation-about">
                  <span className="how-it-works">
                    About
                  </span>
                </Link>
              </div>
              <div className="row">
                <Link to="/for-businesses" className="navigation-for-businesses">
                  <span className="how-it-works">
                    For Businesses
                  </span>
                </Link>
              </div>
              <div className="row">
                <Link to="/about-us" className="navigation-about-us">
                  <span className="how-it-works">
                    About Us
                  </span>
                </Link>
              </div>
              <div className="row">
                <Link to="/explore" className="navigation-explore">
                  <span className="how-it-works">
                    Explore
                  </span>
                </Link>
              </div>
            </div>
            <div className="col">
              <div className="row">
                <Link to="/shop" className="navigation-shop">
                  <span className="shop">
                    Shop
                  </span>
                </Link>
              </div>
              <div className="row">
                <Link to="/home" className="navigation-home">
                  <span className="home">
                    Home
                  </span>
                </Link>
              </div>
              <div className="row">
                <Link to="/how-it-works" className="footer-navigation-how-it-works">
                  <span className="footer-how-it-works">
                    How It Works
                  </span>
                </Link>
              </div>
              <div className="row">
                <Link to="/book-call" className="navigation-book-call">
                  <span className="book-call">
                    Book a Call
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="row footer-links">
                <div className="col links">
                  <Link to="/about-us" className="navigation-about-us-1">
                    <span className="about-us">
                      About us
                    </span>
                  </Link>
                </div>
                <div className="col links">
                  <Link to="/contact" className="navigation-contact">
                    <span className="contact">
                      Contact
                    </span>
                  </Link>
                </div>
                <div className="col links">
                  <Link to="/privacy-policy" className="navigation-privacy-policy">
                    <span className="privacy-policy">
                      Privacy Policy
                    </span>
                  </Link>
                </div>
                <div className="col links">
                  <Link to="/terms-of-use" className="navigation-terms-of-use">
                    <span className="terms-of-use">
                      Terms of Use
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col copyright-block">
              <span className="copyright">
                © 2023-2024, All Rights Reserved
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;