import React from "react";
import acornIcon from "../../assets/images/acorn.png"
import Button from "../atoms/Button";
import {useNavigate} from "react-router-dom";

const RoomVariantInfo = ({roomVariant}) => {
  const navigate = useNavigate();
  
  return (
    <div className="row">
      <div className="col-6 package-price-info">
        <div className="row">
          <span className="package-price">
            Package Total: ${roomVariant.usd}
          </span>
        </div>
        <div className="row">
          <span className="package-price-acorn">
            or $220/mo with
          </span>
          <img src={acornIcon} className="package-acorn-image" alt="package-acorn"/>
        </div>
      </div>
      <div className="col-6 package-buttons">
        <Button
          type="normal"
          text="View Details"
          onButtonClick={() => {
            navigate(`/`);
          }}
        />
      </div>
    </div>
  );
};

export default RoomVariantInfo;