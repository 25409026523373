import React from "react";
import 'swiper/css'
import RoomVariant from "../../molecules/RoomVariant";

const RoomVariants = ({roomVariants}) => {
  const pairsRoomVariants = roomVariants.reduce(function(result, value, index, array) {
    if (index % 2 === 0)
      result.push(array.slice(index, index + 2));
    return result;
  }, []);
  
  return (
    <div className="container">
      {pairsRoomVariants.map((room_variant_pairs, index) =>
        <div className="row" key={index}>
          {room_variant_pairs.map((room_variant, index) =>
           <RoomVariant key={index} roomVariant={room_variant}/>
          )}
        </div>
      )}
    </div>
  )
};
export default RoomVariants;