import React from "react";
import "./style.scss";
import {useNavigate} from "react-router-dom";

const Products = (props) => {
  const navigate = useNavigate();
  const listProducts = props.products || [];
  const roomVariant = props.room_variant;
  const rows = [...Array (Math.ceil(listProducts.length / 3) )];
  const productRows = rows.map( (row, index) => listProducts.slice(index * 3, index * 3 + 3) );
  
  return (
    <div className="container products">
      {
        productRows.map((row, index) => {
          return <div className="row product-info" key={index}>
            { row.map((product) => {
              return <div className="col-md-4 product-wrapper test" key={product.id}>
                        <div className="product-image" onClick={() => { navigate(`/proposal/rooms/${roomVariant.id}/products/${product.id}`) }}>
                          <img width={200} height={230} src={ product.img } alt={ product.name }/>
                        </div>
                        <div className="product-name">{ product.name } <span>({product.furniture_count})</span></div>
                     </div>
            }) }
          </div>
        })
      }
    </div>
  )
};

export default Products;