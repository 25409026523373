import React from "react";
import "./style.scss";
import check_icon from "../../../assets/images/check.svg";

const ListDesignRooms = (props) => {
  const designRooms = props.design_rooms;
  const currentDesignRoom = props.current_design_room;
  const rows = [...Array (Math.ceil(designRooms.length / 2) )];
  const designRoomsRows = rows.map( (row, index) => designRooms.slice(index * 2, index * 2 + 2) );
  
  return (
    <div className="container design-rooms">
      {
        designRoomsRows.map((row, index) => {
          return <div className="row design-room-info" key={index}>
            { row.map((design_room) => {
              return <div className="col-md-6 design-room-item">
                      <div className={`design-rooms-accordion-block ${(design_room.label === currentDesignRoom?.label) ? "active" : ""}`} key={index}>
                        <div className="design-room-content">
                          {design_room.label}
                        </div>
                        { (design_room.label === currentDesignRoom?.label) ? <div className="check-icon-wrap"><div className="check-icon" style={{ backgroundImage: `url(${check_icon})`}}></div></div> : "" }
                      </div>
                    </div>
              })
            }
          </div>
        })
      }
    </div>
  );
}

export default ListDesignRooms;
