import React, { useRef, useState } from "react";
import "./style.scss";
import Products from "../Products/Products";

const Accordion = ({RoomVariant, Room}) => {
  const title = Room.label;
  const products = Room.products;
  const [active, setActive] = useState(false);
  const content = useRef(null);
  const [height, setHeight] = useState("0px");
  
  const toggleAccordion = (e) => {
    if (products !== null && products.length > 0){
      setActive(!active);
      setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
    }
  }
  
  return (
    <div className="accordion__section">
      <div className={`accordion ${active ? "active" : ""}`} onClick={toggleAccordion} >
        <p className="accordion__title">{title}</p>
        <span className="accordion__expand" style={{ marginLeft: "20px" }}>{active ? "- Expanded" : "+ Expand"}</span>
      </div>
      <div ref={content} style={{ maxHeight: `${height}` }} className="accordion__content">
        <div className="accordion__text" />
          <Products products={products} room_variant={RoomVariant}/>
      </div>
    </div>
  );
}

export default Accordion;
