import { createSlice } from "@reduxjs/toolkit";

export const proposalRoomVariant = createSlice({
  name: "proposal_room_variant",
  initialState: {
    room_variant: {
      design_rooms: [],
      feedbacks: []
    },
    design_rooms: []
  },
  reducers: {
    setCurrentRoomVariant: (state, data) => {
      state.room_variant = data.payload
    },
    setListDesignRooms: (state, data) => {
      state.design_rooms = data.payload
    },
    setFeedbacks: (state, data) => {
      state.room_variant.feedbacks = data.payload
    },
    setDesignRoomsProducts: (state, data) => {
      const design_rooms = data.payload.design_rooms;
      const newDesignRooms = [];
      state.design_rooms?.forEach((item) => {
        design_rooms?.forEach((design_room) => {
          newDesignRooms.push(design_room.value === item.value ? {...item, products: design_room.products} : item);
        });
      });
      state.design_rooms = newDesignRooms;
    },
    setDesignRoomProducts: (state, data) => {
      const design_room = data.payload.design_room;
      const newDesignRooms = [];
      state.design_rooms?.forEach((item) => {
        newDesignRooms.push(design_room.value === item.value ? {...item, products: data.payload.design_room.products} : item);
      });
      state.design_rooms = newDesignRooms;
    }
  }
});

export const {
  setCurrentRoomVariant,
  setListDesignRooms,
  setFeedbacks,
  setDesignRoomProducts,
  setDesignRoomsProducts
} = proposalRoomVariant.actions

export default proposalRoomVariant.reducer;