import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "../../../../src/api/axiosConfig"
import { API } from "../../../constants"
import { setCurrentRoomVariant, setListDesignRooms } from "../../../store/proposalRoomVariant";
import 'swiper/css'
import Breadcrumbs from "../../atoms/breadcrumbs/Breadcrumbs";
import RoomVariantImages from "../../molecules/slider/RoomVariantImages";
import RoomVariantInfo from "../../molecules/RoomVariantInfo";
import Accordion from "../../molecules/Accordion/Accordion";
import Feedbacks from "../../molecules/Feedbacks/Feedbacks";

const RoomVariantDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const currentRoomVariant = useSelector((state) => state.proposalRoomVariant.room_variant );
  const designRooms = useSelector((state) => state.proposalRoomVariant.design_rooms);
  
  useEffect(() => {
    axios
      .get(`${API}/proposals/room_variants/${id}`)
      .then((response) => {
        dispatch(setCurrentRoomVariant(response.data.data));
        dispatch(setListDesignRooms(response.data.data.design_rooms))
      });
  }, [dispatch, id]);
  
  return (
    <div className="container">
      <div className="row breadcrumb-row">
        <div className="col">
          <Breadcrumbs roomVariant={currentRoomVariant}/>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <span className="room-variant-name">
            {currentRoomVariant.name}
          </span>
        </div>
        <div className="col-6">
          <span className="view-layout-link">
            View Layout
          </span>
        </div>
      </div>
      <div className="row package-slider">
        <RoomVariantImages roomVariant={currentRoomVariant}/>
      </div>
      <div className="row package-detail-info">
        <RoomVariantInfo roomVariant={currentRoomVariant} />
      </div>
      <div className="row package-list-design-rooms">
        {designRooms.map((room, index) => { return <Accordion key={index} RoomVariant={currentRoomVariant} Room={room}/> })}
      </div>
      <div className="row package-feedback">
        <Feedbacks RoomVariant={currentRoomVariant}/>
      </div>
    </div>
  )
};
export default RoomVariantDetails;