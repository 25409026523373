import { createSlice } from "@reduxjs/toolkit";

export const proposalProducts = createSlice({
  name: "proposal_products",
  initialState: {
    product: {},
  },
  reducers: {
    setCurrentProduct: (state, data) => {
      state.product = data.payload
    },
  }
});

export const {
  setCurrentProduct,
} = proposalProducts.actions

export default proposalProducts.reducer;